'use client';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import classes from './ChatSection.module.css';
import SendPicture from './SendPicture';
import { useAuthContext } from '@/context';
import { useChatsContext } from '@/context/chats';
import { useAlertContext } from '@/context/alert';
import { ChevronLeft } from 'react-feather';

const ChatSection = (props) => {
	const { chatSelection, socket, setChatSelection, setOpen } = props;

	const { SET_ALERT } = useAlertContext();
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const { GET_ALL_CHATS, REMOVE_CHAT_NOT, chats } = useChatsContext();
	const { auth } = useAuthContext();

	const dummy = useRef();
	const timeslotsRef = useRef();

	const [seller, setSeller] = useState();
	const [buyer, setBuyer] = useState();
	const [receiver, setReceiver] = useState();
	const [sender, setSender] = useState();
	const [order, setOrder] = useState();
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);

	const [selectedImage, setSelectedImage] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (auth.isAuthenticated && chatSelection) {
			REMOVE_CHAT_NOT({ userId: auth.user._id, chatId: chatSelection._id });
		}
	}, [chatSelection, auth]);

	//Find chat, listing, order and messages
	useEffect(() => {
		setMessages(chatSelection.messages);
		setOrder(chatSelection.order);
		setSeller(chatSelection.seller);
		setBuyer(chatSelection.buyer);

		if (!auth.loading && auth.isAuthenticated) {
			socket.emit('getMessages', {
				orderId: chatSelection.order._id,
				senderId: auth.user._id,
			});

			setSender(auth.user);
			setReceiver(
				auth.user._id.toString() === chatSelection.buyer._id.toString()
					? chatSelection.seller
					: chatSelection.buyer
			);
		}
	}, [chatSelection, auth]);

	useEffect(() => {
		if (chats.loading) {
			getChat();
		}
	}, [chats]);

	const getChat = async () => {
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/${chatSelection._id}`
			)
			.then((res) => {
				setChatSelection(res.data);
				setOrder(res.data.order);
			})
			.catch((err) => console.log(err));
	};

	const getMessages = () => {
		if (sender) {
			GET_ALL_CHATS();
			getChat();
			socket.emit('getMessages', {
				orderId: chatSelection.order._id,
				senderId: sender._id,
			});
		}
	};

	//Scroll to last message
	useEffect(() => {
		if (
			!auth.loading &&
			auth.isAuthenticated &&
			receiver &&
			messages &&
			messages.length > 0
		) {
			dummy.current && dummy.current.scrollIntoView({ block: 'nearest' });
		}
	}, [auth, receiver, messages]);

	//Redirect if sender and receiver are the same user or if this user is not part of this chat
	useEffect(() => {
		if (sender && receiver) {
			if (receiver._id === sender._id) {
				router.push(`/profile/${receiver._id}`);
			}
		}
		if (buyer && seller && sender) {
			if (sender._id !== buyer._id && sender._id !== seller._id) {
				SET_ALERT({ msg: `Permission delied`, type: 'danger' });
				router.push(`/profile/${receiver._id}`);
			}
		}
	}, [receiver, sender, buyer, seller]);

	//Send message
	const sendMessage = async (event) => {
		event.preventDefault();
		if (receiver && !auth.loading && auth.isAuthenticated && message) {
			socket.emit('message', {
				message,
				usr: auth.user._id,
				ord: order._id,
				rec: receiver._id,
			});
			setMessage('');
		}
	};

	//Websocket listening for incoming messages
	socket.on('messages', async function (data) {
		const { msg, ord } = data;

		if (order && order._id === ord) {
			setMessages(msg);
			setMessage('');

			// setOrder(data.ord);
			dummy.current && dummy.current.scrollIntoView({ block: 'nearest' });

			return;
		}
	});

	//Send an Image in the Chat

	const data = new FormData();

	const sendImage = async () => {
		if (selectedImage) {
			const image = selectedImage;
			data.append('image', image);
			setLoading(true);
			await axios
				.post(
					`${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/${chatSelection._id}`,
					data
				)
				.then((res) => {
					getMessages();
				})
				.catch((error) => {
					SET_ALERT({
						msg: 'Server error',
						type: 'danger',
					});

					getMessages();
				});
			setLoading(false);
		}
	};
	useEffect(() => {
		sendImage();
	}, [selectedImage]);

	let length = chatSelection.messages.length - 1;

	const goBackHandler = () => {
		console.log('go back, ChatSection');

		const params = new URLSearchParams(searchParams);
		params.delete('chat');
		setChatSelection();
		router.push(`${pathname}${params ? `?${params.toString()}` : ''}`);
	};

	const goToOrderHandler = () => {
		let selection =
			order.status === 'requested' ||
			order.status === 'scheduling' ||
			order.status === 'active-delivery' ||
			order.status === 'looking_for_carrier' ||
			order.status === 'awaiting_shipping' ||
			order.status === 'in_transit' ||
			order.status === 'active-pickup'
				? 'openPurchases'
				: order.status === 'cancelled' ||
				  order.status === 'canceled' ||
				  order.status === 'expired' ||
				  (order.status === 'deleted' && !order.offer) ||
				  (order.status === 'deleted' && order.offer && order.approved)
				? 'closedPurchases'
				: order.status === 'offer-sent' && order.offer
				? 'openOffers'
				: 'closedOffers';
		let tab =
			selection === 'openPurchases' || selection === 'closedPurchases'
				? 'purchases'
				: 'offers';

		router.push(
			`/${
				buyer._id === sender._id ? 'purchases' : 'sales'
			}?&selection=${selection}&tab=${tab}&order=${order._id} `
		);

		setChatSelection();
		setOpen(false);
	};

	// const findDisplayTitle = (listing) => {
	// 	if (listing.velouAttributes?.generatedContent) {
	// 		const title = listing.velouAttributes.generatedContent.find
	// 			? listing.velouAttributes.generatedContent.find(
	// 					({ fieldName }) => fieldName === 'productTitle'
	// 			  )
	// 			: null;
	// 		if (title) {
	// 			return title.values['en-us'];
	// 		}
	// 	}
	// 	return listing.title;
	// };

	function linkify(text) {
		const urlPattern =
			/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
		return text.replace(
			urlPattern,
			'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
		);
	}

	return (
		<>
			{sender && receiver && order && chatSelection && (
				<>
					<div className={classes.listingBox}>
						<div className={`${classes.paddingChat} `}>
							<div>
								<ChevronLeft
									onClick={() => goBackHandler()}
									className={classes.iconBack}
								/>
							</div>

							<div className={classes.box}>
								<Link href={`/profile/${receiver._id}`}>
									<img
										src={`${receiver.picture}?tr=w-50,h-50`}
										alt={`${receiver.firstName} - Kashew used furniture san francisco `}
										className={classes.receiverPicture}
										referrerPolicy="no-referrer"
									/>
								</Link>
								<div className={classes.name}>
									<b>
										{' '}
										{receiver.isBusiness && order.status === 'offer-sent'
											? 'Local business seller'
											: receiver.firstName}
									</b>
								</div>
							</div>

							<div
								className={
									pathname.includes('/orders')
										? classes.displayNone
										: classes.orderDetails
								}
							/>
						</div>

						<div
							className={`${classes.paddingChat1} `}
							onClick={goToOrderHandler}
						>
							<LazyLoadImage
								loading="lazy"
								src={`${
									chatSelection.listing
										? chatSelection.listing.images[0]
										: chatSelection.listings.length > 0 &&
										  chatSelection.listings[0].listing.images[0]
								}?tr=w-300,h-300`}
								alt={'used furniture image'}
								className={classes.listingImage}
							/>
							<div className={classes.infoContainer}>
								<div className={classes.col}>
									<div className={classes.textHeadingChat}>
										{chatSelection.listing
											? chatSelection.listing.title
											: chatSelection.listings[0].listing.title}{' '}
										{chatSelection.listings.length - 1 > 0 && (
											<>+ {chatSelection.listings.length - 1} more </>
										)}
									</div>
									{chatSelection.offer &&
									chatSelection.messages[length] &&
									chatSelection.messages[length].offer ? (
										<div className={classes.flexRow}>
											${chatSelection.offer.amount}{' '}
											<div className={classes.originalPrice}>
												${chatSelection.listings[0].listing.price}
											</div>
											{(chatSelection &&
												chatSelection.listing &&
												chatSelection.listing.status &&
												chatSelection.listing.status === 'sold') ||
											(chatSelection &&
												chatSelection.listings[0] &&
												chatSelection.listings[0].listing.status !== 'active' &&
												chatSelection.listings[0].listing.soldTo !==
													chatSelection.order._id) ? (
												<div className={classes.soldListing}>SOLD</div>
											) : (
												''
											)}
										</div>
									) : (
										<div className={classes.flexRow}>
											{chatSelection.listings[0] ? (
												<>${chatSelection.listings[0].listing.price} </>
											) : (
												''
											)}
										</div>
									)}
								</div>
								<div className={classes.orderTypePill}>
									{chatSelection.order.status === 'offer-sent'
										? 'offer'
										: chatSelection.order.scheduleViewing
										? 'viewing scheduled'
										: chatSelection.order.type === 'delivery'
										? 'delivery'
										: 'pickup'}
								</div>
							</div>
						</div>

						<div className={classes.messagesContainer} ref={timeslotsRef}>
							<div className={classes.chatStart}>
								<p className={classes.p}>
									For your safety and protection, please conduct all
									communications and financial transactions through Kashew.
								</p>
							</div>

							{messages &&
								messages.length > 0 &&
								messages.map((message, idx) => (
									<span key={idx}>
										{message.type === 'text' ? (
											<>
												<div
													className={
														message.sender === sender._id
															? classes.newMyMessage
															: classes.newNotMyMessage
													}
													key={message._id}
												>
													<div
														className={
															message.sender === sender._id
																? classes.myMessage
																: classes.notMyMessage
														}
													>
														<h6 className={`${classes.text} `}>
															<div
																dangerouslySetInnerHTML={{
																	__html: linkify(message.text),
																}}
															/>
														</h6>
													</div>
													<div className={classes.dateDiv}>
														{moment(message.date).fromNow()}
													</div>
												</div>
											</>
										) : message.type === 'image' ? (
											<div
												className={
													message.sender === sender._id
														? classes.newMyMessageImage
														: classes.newNotMyMessageImage
												}
												key={message._id}
											>
												<div
													className={
														message.sender === sender._id
															? classes.myMessageImage
															: classes.notMyMessageImage
													}
												>
													<h6 className={`${classes.textImage}`}>
														<img
															src={message.imageUrl}
															alt="photo"
															className={classes.sentImage}
														/>
													</h6>
												</div>
											</div>
										) : (
											''
										)}
									</span>
								))}

							<span ref={dummy} id="dummy"></span>
						</div>

						{chats.loading ||
							(loading && (
								<div className={classes.centerLoadingSpinner}>
									{' '}
									<Spinner animation="border" variant="primary" />{' '}
								</div>
							))}

						{order.status !== 'deleted' &&
						order.status !== 'offer-sent' &&
						order.status !== 'completed' &&
						order.status !== 'completed-off-kashew' &&
						order.approved ? (
							<>
								<form className={classes.inputDiv} onSubmit={sendMessage}>
									<div className={classes.inputWrapper}>
										<textarea
											value={message}
											onChange={(e) => setMessage(e.target.value)}
											className={classes.input}
											placeholder={'Type a message'}
										/>
										<SendPicture
											className={classes.sendPic}
											setSelectedImage={setSelectedImage}
										/>
									</div>

									<button
										type="submit"
										className={
											message ? classes.sendButton : classes.sendButtonDeactive
										}
									>
										Send
									</button>
								</form>
							</>
						) : (
							<div className={classes.closedChat}>
								This chat has been closed
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default ChatSection;
