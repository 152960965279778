'use client';
import { createContext, useState, useContext } from 'react';
import { useAuthContext } from '.';
import { useEffect } from 'react';

// Create the cart context with default values
const ProductsContext = createContext({
	allProducts: {},
	allPage: { current: 1, loaded: 1, total: 1 },
	activeProducts: {},
	activePage: { current: 1, loaded: 1, total: 1 },
	soldProducts: {},
	soldPage: { current: 1, loaded: 1, total: 1 },
	inactiveProducts: {},
	inactivePage: { current: 1, loaded: 1, total: 1 },
	searchProducts: {},
	searchPage: { current: 1, loaded: 1, total: 1 },
	SAVE_DASHBOARD_ALLPRODUCTS: () => {},
	SAVE_DASHBOARD_ACTIVEPRODUCTS: () => {},
	SAVE_DASHBOARD_SOLDPRODUCTS: () => {},
	SAVE_DASHBOARD_INACTIVEPRODUCTS: () => {},
	SAVE_DASHBOARD_SEARCHPRODUCTS: () => {},
	EMPTY_DASHBOARD_SEARCHPRODUCTS: () => {},
	SET_ALLPRODUCTS_PAGE: () => {},
	SET_ACTIVEPRODUCTS_PAGE: () => {},
	SET_SOLDPRODUCTS_PAGE: () => {},
	SET_INACTIVEPRODUCTS_PAGE: () => {},
	SET_SEARCHPRODUCTS_PAGE: () => {},
});

// Create a provider component for the cart context
export const ProductsProvider = ({ children }) => {
	const [productState, setProductState] = useState({
		allProducts: {},
		allPage: { current: 1, loaded: 1, total: 1 },

		activeProducts: {},
		activePage: { current: 1, loaded: 1, total: 1 },

		soldProducts: {},
		soldPage: { current: 1, loaded: 1, total: 1 },

		inactiveProducts: {},
		inactivePage: { current: 1, loaded: 1, total: 1 },

		searchProducts: {},
		searchPage: { current: 1, loaded: 1, total: 1 },
	});

	const { auth } = useAuthContext();

	useEffect(() => {
		if (!auth.isAuthenticated) {
			setProductState({
				allProducts: {},
				allPage: { current: 1, loaded: 1, total: 1 },

				activeProducts: {},
				activePage: { current: 1, loaded: 1, total: 1 },

				soldProducts: {},
				soldPage: { current: 1, loaded: 1, total: 1 },

				inactiveProducts: {},
				inactivePage: { current: 1, loaded: 1, total: 1 },

				searchProducts: {},
				searchPage: { current: 1, loaded: 1, total: 1 },
			});
		}
	}, [auth]);

	const SAVE_DASHBOARD_ALLPRODUCTS = ({
		products,
		currentPage,
		totalPages,
	}) => {
		const newProducts = { ...productState };
		newProducts.allProducts[currentPage] = products;
		newProducts.allPage.total = totalPages;
		newProducts.allPage.current = currentPage;
		if (currentPage > newProducts.allPage.loaded) {
			newProducts.allPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SAVE_DASHBOARD_ACTIVEPRODUCTS = ({
		products,
		currentPage,
		totalPages,
	}) => {
		const newProducts = { ...productState };
		newProducts.activeProducts[currentPage] = products;
		newProducts.activePage.total = totalPages;
		newProducts.activePage.current = currentPage;
		if (currentPage > newProducts.activePage.loaded) {
			newProducts.activePage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SAVE_DASHBOARD_SOLDPRODUCTS = ({
		products,
		currentPage,
		totalPages,
	}) => {
		const newProducts = { ...productState };
		newProducts.soldProducts[currentPage] = products;
		newProducts.soldPage.total = totalPages;
		newProducts.soldPage.current = currentPage;
		if (currentPage > newProducts.soldPage.loaded) {
			newProducts.soldPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SAVE_DASHBOARD_INACTIVEPRODUCTS = ({
		products,
		currentPage,
		totalPages,
	}) => {
		const newProducts = { ...productState };
		newProducts.inactiveProducts[currentPage] = products;
		newProducts.inactivePage.total = totalPages;
		newProducts.inactivePage.current = currentPage;
		if (currentPage > newProducts.inactivePage.loaded) {
			newProducts.inactivePage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SAVE_DASHBOARD_SEARCHPRODUCTS = ({
		products,
		currentPage,
		totalPages,
	}) => {
		const newProducts = { ...productState };
		newProducts.searchProducts[currentPage] = products;
		newProducts.searchPage.total = totalPages;
		newProducts.searchPage.current = currentPage;
		if (currentPage > newProducts.searchPage.loaded) {
			newProducts.searchPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const EMPTY_DASHBOARD_SEARCHPRODUCTS = () => {
		const newProducts = productState ? { ...productState } : {};
		newProducts.searchProducts = {};
		newProducts.searchPage.total = 1;
		newProducts.searchPage.current = 1;
		setProductState(newProducts);
	};

	const SET_ALLPRODUCTS_PAGE = ({ currentPage }) => {
		const newProducts = { ...productState };
		newProducts.allPage.current = currentPage;
		if (currentPage > newProducts.allPage.loaded) {
			newProducts.allPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SET_ACTIVEPRODUCTS_PAGE = ({ currentPage }) => {
		const newProducts = { ...productState };
		newProducts.activePage.current = currentPage;
		if (currentPage > newProducts.activePage.loaded) {
			newProducts.activePage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SET_SOLDPRODUCTS_PAGE = ({ currentPage }) => {
		const newProducts = { ...productState };
		newProducts.soldPage.current = currentPage;
		if (currentPage > newProducts.soldPage.loaded) {
			newProducts.soldPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SET_INACTIVEPRODUCTS_PAGE = ({ currentPage }) => {
		const newProducts = { ...productState };
		newProducts.inactivePage.current = currentPage;
		if (currentPage > newProducts.inactivePage.loaded) {
			newProducts.inactivePage.loaded = currentPage;
		}
		setProductState(newProducts);
	};
	const SET_SEARCHPRODUCTS_PAGE = ({ currentPage }) => {
		const newProducts = { ...productState };
		newProducts.searchPage.current = currentPage;
		if (currentPage > newProducts.searchPage.loaded) {
			newProducts.searchPage.loaded = currentPage;
		}
		setProductState(newProducts);
	};

	return (
		<ProductsContext.Provider
			value={{
				products: productState,
				SAVE_DASHBOARD_ALLPRODUCTS,
				SAVE_DASHBOARD_ACTIVEPRODUCTS,
				SAVE_DASHBOARD_SOLDPRODUCTS,
				SAVE_DASHBOARD_INACTIVEPRODUCTS,
				SAVE_DASHBOARD_SEARCHPRODUCTS,
				EMPTY_DASHBOARD_SEARCHPRODUCTS,
				SET_ALLPRODUCTS_PAGE,
				SET_ACTIVEPRODUCTS_PAGE,
				SET_SOLDPRODUCTS_PAGE,
				SET_INACTIVEPRODUCTS_PAGE,
				SET_SEARCHPRODUCTS_PAGE,
			}}
		>
			{children}
		</ProductsContext.Provider>
	);
};

// Custom hook to use the cart context
export const useProductsContext = () => {
	return useContext(ProductsContext);
};
