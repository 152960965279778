'use client';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import classes from './AlertMsg.module.css';
import { useAlertContext } from '@/context/alert';
import { XCircle } from 'react-feather';

const AlertMsg = ({ alert }) => {
	const { REMOVE_ALERT } = useAlertContext();

	useEffect(() => {
		setTimeout(() => REMOVE_ALERT(alert.id), 7000);
	}, []);

	const closeAlert = () => REMOVE_ALERT(alert.id);

	return (
		<>
			<motion.div
				initial={{ y: '100vh' }}
				animate={{ y: 0 }}
				exit={{ y: '100vh' }}
				transition={{
					duration: 0.6,
				}}
				className={classes.fixed}
			>
				<div
					className={
						alert.type == 'danger' ? classes.alertDanger : classes.alert
					}
				>
					<p className={classes.msg}>{alert.msg}</p>

					<XCircle className={classes.icon} onClick={closeAlert} size={15} />
					{alert && alert.type === 'danger' && (
						<div className={classes.progressBar}>
							<div className={classes.progress} />
						</div>
					)}
					{alert && alert.type === 'success' && (
						<div className={classes.progressBarSuccess}>
							<div className={classes.progressSuccess} />
						</div>
					)}
				</div>
			</motion.div>
		</>
	);
};

export default AlertMsg;
