'use client';

import { FaPinterestSquare } from 'react-icons/fa';
import Link from 'next/link';
import Image from 'next/image';
import classes from './Footer.module.css';
import { usePathname } from 'next/navigation';
import { useAuthContext } from '@/context';
import { useEffect, useState } from 'react';
import { Facebook, Instagram, Linkedin } from 'react-feather';

const Footer = ({ params, searchParams }) => {
	const { auth } = useAuthContext();
	const pathname = usePathname();

	const [windowInnerWidth, setWindowInnerWidth] = useState(false);

	useEffect(() => {
		setWindowInnerWidth(window.innerWidth);
	}, []);

	return (
		<>
			{pathname !== '/login' &&
			pathname !== '/register' &&
			pathname !== '/consign-with-us' &&
			!pathname.includes('/edit') &&
			!pathname.includes('/chat') &&
			!pathname.includes('/checkout') &&
			!pathname.includes('/orders') &&
			!pathname.includes('/cart') &&
			pathname !== '/seller-dashboard' &&
			!pathname.includes('order-summary') &&
			pathname !== '/consign-form' &&
			pathname !== '/admin' &&
			!pathname.includes('seller-dashboard') ? (
				<footer className={classes.main}>
					<div className={classes.container}>
						<section className={classes.valueProps}>
							<h4 className={classes.title1}>Why We&apos;re Magic</h4>
							<section className={classes.grid}>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/character_icon.png?tr=w-200,h-200'
										}
										alt={
											'quality used furniture that is unique, mcm, and vintage'
										}
									/>

									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>Character</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												Choosing pre-owned furniture, where every piece tells a
												story, is the quickest way to infuse your home with
												unique charm.
											</div>
										) : (
											<div className={classes.propText}>
												The quickest way to infuse your home with unique charm.
											</div>
										)}
									</section>
								</div>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/planet_icon.png?tr=w-200,h-200'
										}
										alt={'Sustainable Furniture Kashew. Buy used furniture.'}
									/>
									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>Sustainable</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												Each year, more than 12 million tons of furniture end up
												in U.S. landfills. By choosing pre-owned, you are
												shopping planet first.
											</div>
										) : (
											<div className={classes.propText}>
												By choosing pre-owned, you are shopping planet first.
											</div>
										)}
									</section>
								</div>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/cherry_icon.png?tr=w-200,h-200'
										}
										alt={'quality used furniture for sale close to me. Kashew'}
									/>
									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>
											Cherry-Picked {windowInnerWidth > 600 && 'Sellers'}
										</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												We rigorously vet our sellers for quality and accuracy,
												guaranteeing that you always receive the best the world
												of pre-loved furniture has to offer.
											</div>
										) : (
											<div className={classes.propText}>
												The best the world of pre-loved furniture has to offer.
											</div>
										)}
									</section>
								</div>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/support_icon.png?tr=w-200,h-200'
										}
										alt={'Customer support Kashew. Buy used furniture online.'}
									/>
									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>Customer Support</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												We&apos;re here to assist you with any questions you
												might have. Call us anytime – we&apos;re happy to help.
											</div>
										) : (
											<div className={classes.propText}>
												Call us anytime – we&apos;re happy to help.
											</div>
										)}
									</section>
								</div>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/lead_time_icon.png?tr=w-200,h-200'
										}
										alt={
											'used furniture delivered next day. kashew buy and sell used furniture.'
										}
									/>
									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>No Backorders</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												No waiting for your item to be back in stock with
												furniture that&apos;s already in circulation. What you
												see is what you get.
											</div>
										) : (
											<div className={classes.propText}>
												No waiting with furniture that&apos;s already in
												circulation.
											</div>
										)}
									</section>
								</div>
								<div className={classes.propBox}>
									<Image
										width={80}
										height={80}
										loading="lazy"
										className={classes.propIcon}
										src={
											'https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/return_icon.png?tr=w-200,h-200'
										}
										alt={
											'Returns guaranteed Kashew. Buy used furniture online. mcm, vintage, mid century modern furniture.'
										}
									/>
									<section className={classes.gridText}>
										<h5 className={classes.propTitle}>Returns Guaranteed</h5>
										{windowInnerWidth > 600 ? (
											<div className={classes.propText}>
												Get your item as described or your money back. Returns
												are free and we handle it for you.
											</div>
										) : (
											<div className={classes.propText}>
												Get your item as described or your money back.
											</div>
										)}
									</section>
								</div>
							</section>
						</section>

						<h3 className={classes.title}>
							Live Sustainably. <br /> Buy Second Hand.
						</h3>

						<div className={classes.sections}>
							<div className={classes.firstSection}>
								<Image
									width={80}
									height={80}
									loading="lazy"
									src={
										'https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/logo/svg/logo-black.svg'
									}
									className={classes.logo}
									alt={'Kashew Second Hand Furniture San Francisco Bay Area'}
								/>
								<div className={classes.socials}>
									<a
										aria-label="Go to Kashew Instagram"
										href={'https://www.instagram.com/wekashew/'}
										target="_blank"
										rel="noreferrer noopener"
									>
										<Instagram className={classes.social} />
									</a>
									<a
										aria-label="Go to Kashew Facebook"
										href={
											'https://www.facebook.com/pages/category/Furniture/Kashew-103649468435824/'
										}
										target="_blank"
										rel="noreferrer noopener"
									>
										<Facebook className={classes.social} />
									</a>
									<a
										aria-label="Go to Kashew Pinterest"
										href={'https://ch.pinterest.com/kashewfurniture/'}
										target="_blank"
										rel="noreferrer noopener"
									>
										<FaPinterestSquare className={classes.social2} />
									</a>
									<a
										aria-label="Go to Kashew LinkedIn"
										href={'https://www.linkedin.com/company/kashew'}
										target="_blank"
										rel="noreferrer noopener"
									>
										<Linkedin className={classes.social} />
									</a>
								</div>
								<Link
									aria-label="Go to Kashew collections"
									href={'/collections'}
									className={classes.link}
								>
									<div className={classes.button}>Explore Kashew</div>
								</Link>
							</div>
							<div className={classes.firstSection}>
								<Link className={classes.unstyled} href={'/collections'}>
									Start exploring Kashew
								</Link>
								<Link className={classes.unstyled} href={'/consign-with-us'}>
									Start selling
								</Link>
								<Link className={classes.unstyled} href={'/how-it-works'}>
									How Kashew works
								</Link>
								<Link className={classes.unstyled} href={'/blog'}>
									Our blog
								</Link>
								<Link className={classes.unstyled} href={'/trade'}>
									Trade program
								</Link>
							</div>
							<div className={classes.firstSection}>
								<Link
									className={classes.unstyled}
									target="_blank"
									href={'/contact'}
									aria-label="Go to contact us"
								>
									Contact us
								</Link>
								<a
									className={classes.unstyled}
									aria-label="Go to job openings"
									rel="noreferrer noopener"
									target="_blank"
									href={'https://www.angel.co/company/Kashew'}
								>
									Job openings
								</a>
								<Link
									className={classes.unstyled}
									aria-label="Go to info and help"
									target="_blank"
									href={'/info'}
								>
									Info & help
								</Link>
								<Link
									aria-label="Go to privacy policy"
									className={classes.unstyled}
									target="_blank"
									href={'/privacy-policy'}
								>
									Privacy policy
								</Link>
							</div>

							<div className={classes.firstSection}>
								{auth.isAuthenticated && !auth.loading && auth.user ? (
									<>
										<Link
											aria-label="Go to my profile"
											className={classes.unstyled}
											href={`/profile/${auth.user._id}`}
										>
											My profile
										</Link>
										<Link
											className={classes.unstyled}
											href={'/purchases'}
											aria-label="Go to my purchases"
										>
											My purchases
										</Link>
										<Link
											className={classes.unstyled}
											href={'/sales'}
											aria-label="Go to my sales"
										>
											My sales
										</Link>
										<Link
											className={classes.unstyled}
											href={'/favorites'}
											aria-label="Go to my favorites"
										>
											My favorites
										</Link>
									</>
								) : (
									<>
										<Link
											className={classes.unstyled}
											href={'/login'}
											aria-label="Go to login"
										>
											Login
										</Link>
										<Link
											className={classes.unstyled}
											href={'/register'}
											aria-label="Go to register"
										>
											Register
										</Link>
									</>
								)}
							</div>
							<div className={classes.lastSection}>
								<h4 className={classes.prop}>
									The easiest way to buy and sell second hand furniture
								</h4>
								<div className={classes.credits}>
									© 2025 Copyright: Kashew Corp
								</div>
							</div>
						</div>
					</div>
				</footer>
			) : (
				''
			)}{' '}
		</>
	);
};

export default Footer;
