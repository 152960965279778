'use client';

import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { useAlertContext } from './alert';
import { useAuthContext } from '.';

// Create the cart context with default values
const ConsignmentsContext = createContext({
	availableConsignments: {},
	availablePage: { current: 1, loaded: 1, total: 1 },

	acceptedConsignments: {},
	acceptedPage: { current: 1, loaded: 1, total: 1 },

	missedConsignments: {},
	missedPage: { current: 1, loaded: 1, total: 1 },

	searchConsignments: {},
	searchPage: { current: 1, loaded: 1, total: 1 },

	loading: false,

	SAVE_AVAILABLE_CONSIGNMENTS: () => {},
	SAVE_ACCEPTED_CONSIGNMENTS: () => {},
	SAVE_MISSED_CONSIGNMENTS: () => {},
	SAVE_SEARCH_CONSIGNMENTS: () => {},
	EMPTY_SEARCH_PRODUCTS: () => {},
	SET_AVAILABLE_PAGE: () => {},
	SET_ACCEPTED_PAGE: () => {},
	SET_MISSED_PAGE: () => {},
	SET_SEARCH_PAGE: () => {},
	LOAD_CONSIGNMENTS: () => {},
});

// Create a provider component for the cart context
export const ConsignmentsProvider = ({ children }) => {
	const { SET_ALERT } = useAlertContext();

	const [consignmentsState, setConsignments] = useState({
		availableConsignments: {},
		availablePage: { current: 1, loaded: 1, total: 1 },

		acceptedConsignments: {},
		acceptedPage: { current: 1, loaded: 1, total: 1 },

		missedConsignments: {},
		missedPage: { current: 1, loaded: 1, total: 1 },

		searchConsignments: {},
		searchPage: { current: 1, loaded: 1, total: 1 },

		loading: false,
	});

	const SAVE_AVAILABLE_CONSIGNMENTS = ({
		consignments,
		currentPage,
		totalPages,
	}) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.availableConsignments[currentPage] = consignments;
		newConsignments.availablePage.total = totalPages;
		newConsignments.availablePage.current = currentPage;
		if (currentPage > newConsignments.availablePage.loaded) {
			newConsignments.availablePage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};

	const SAVE_ACCEPTED_CONSIGNMENTS = ({
		consignments,
		currentPage,
		totalPages,
	}) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.acceptedConsignments[currentPage] = consignments;
		newConsignments.acceptedPage.total = totalPages;
		newConsignments.acceptedPage.current = currentPage;
		if (currentPage > newConsignments.acceptedPage.loaded) {
			newConsignments.acceptedPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};
	const SAVE_MISSED_CONSIGNMENTS = ({
		consignments,
		currentPage,
		totalPages,
	}) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.missedConsignments[currentPage] = consignments;
		newConsignments.missedPage.total = totalPages;
		newConsignments.missedPage.current = currentPage;
		if (currentPage > newConsignments.missedPage.loaded) {
			newConsignments.missedPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};
	const SAVE_SEARCH_CONSIGNMENTS = ({
		consignments,
		currentPage,
		totalPages,
	}) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.searchConsignments[currentPage] = consignments;
		newConsignments.searchPage.total = totalPages;
		newConsignments.searchPage.current = currentPage;
		if (currentPage > newConsignments.searchPage.loaded) {
			newConsignments.searchPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};

	const EMPTY_SEARCH_PRODUCTS = () => {
		let newConsignments = { ...consignmentsState };
		newConsignments.searchConsignments = {};
		newConsignments.searchPage.total = 1;
		newConsignments.searchPage.current = 1;
		setConsignments(newConsignments);
	};

	const SET_AVAILABLE_PAGE = ({ currentPage }) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.availablePage.current = currentPage;
		if (currentPage > newConsignments.availablePage.loaded) {
			newConsignments.availablePage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};
	const SET_ACCEPTED_PAGE = ({ currentPage }) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.acceptedPage.current = currentPage;
		if (currentPage > newConsignments.acceptedPage.loaded) {
			newConsignments.acceptedPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};
	const SET_MISSED_PAGE = ({ currentPage }) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.missedPage.current = currentPage;
		if (currentPage > newConsignments.missedPage.loaded) {
			newConsignments.missedPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};
	const SET_SEARCH_PAGE = ({ currentPage }) => {
		let newConsignments = { ...consignmentsState };
		newConsignments.searchPage.current = currentPage;
		if (currentPage > newConsignments.searchPage.loaded) {
			newConsignments.searchPage.loaded = currentPage;
		}
		setConsignments(newConsignments);
	};

	const LOAD_CONSIGNMENTS = async ({ type, page }) => {
		setConsignments((prevState) => ({
			...prevState,
			loading: true,
		}));
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/consign/get-consignments/${page}/${type}`
			)
			.then(async (res) => {
				const { paginationData, consignments } = res.data;
				if (type === 'available') {
					SAVE_AVAILABLE_CONSIGNMENTS({
						consignments: consignments,
						currentPage: paginationData.currentPage,
						totalPages: paginationData.totalPages,
					});
				} else if (type === 'accepted') {
					SAVE_ACCEPTED_CONSIGNMENTS({
						consignments: consignments,
						currentPage: paginationData.currentPage,
						totalPages: paginationData.totalPages,
					});
				} else if (type === 'missed') {
					SAVE_MISSED_CONSIGNMENTS({
						consignments: consignments,
						currentPage: paginationData.currentPage,
						totalPages: paginationData.totalPages,
					});
				} else {
					SAVE_SEARCH_CONSIGNMENTS({
						consignments: consignments,
						currentPage: paginationData.currentPage,
						totalPages: paginationData.totalPages,
					});
				}
			})
			.catch(async (error) => {
				console.log(error);
				SET_ALERT({
					msg: 'Error getting consignment',
					type: 'danger',
				});
			});
		setConsignments((prevState) => ({
			...prevState,
			loading: false,
		}));
	};

	const { auth } = useAuthContext();

	useEffect(() => {
		if (!auth.isAuthenticated) {
			setConsignments({
				availableConsignments: {},
				availablePage: { current: 1, loaded: 1, total: 1 },

				acceptedConsignments: {},
				acceptedPage: { current: 1, loaded: 1, total: 1 },

				missedConsignments: {},
				missedPage: { current: 1, loaded: 1, total: 1 },

				searchConsignments: {},
				searchPage: { current: 1, loaded: 1, total: 1 },

				loading: false,
			});
		}
	}, [auth]);

	return (
		<ConsignmentsContext.Provider
			value={{
				consignments: consignmentsState,
				SAVE_AVAILABLE_CONSIGNMENTS,
				SAVE_ACCEPTED_CONSIGNMENTS,
				SAVE_MISSED_CONSIGNMENTS,
				SAVE_SEARCH_CONSIGNMENTS,
				EMPTY_SEARCH_PRODUCTS,
				SET_AVAILABLE_PAGE,
				SET_ACCEPTED_PAGE,
				SET_MISSED_PAGE,
				SET_SEARCH_PAGE,
				LOAD_CONSIGNMENTS,
			}}
		>
			{children}
		</ConsignmentsContext.Provider>
	);
};

// Custom hook to use the cart context
export const useConsignmentsContext = () => {
	return useContext(ConsignmentsContext);
};
