'use client';
import classes from './NavigationBarClickable.module.css';
import { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { newFilters } from '@/utils/newFilters';
import { usePathname } from 'next/navigation';
import { useListingsContext } from '@/context/listings';

const NavigationBarClickable = () => {
	const pathname = usePathname();
	const [activeCategory, setActiveCategory] = useState(null);
	const [hoveredCategory, setHoveredCategory] = useState(null);
	const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
	const [styles, setStyles] = useState([]);
	const [brands, setBrands] = useState([]);
	const [rooms, setRooms] = useState([]);

	const handleCategoryMouseEnter = (category) => {
		setHoveredCategory(category.subcategories);
		setHoveredSubCategory(null);
	};

	const handleSubCategoryMouseEnter = (subCategory) => {
		setHoveredSubCategory(subCategory);
	};

	const handleMainItemMouseEnter = (item) => {
		setHoveredCategory(null);
		setHoveredSubCategory(null);

		setActiveCategory(item);
	};

	const handleMainMouseLeave = () => {
		setActiveCategory(null);
		setHoveredCategory(null);
		setHoveredSubCategory(null);
	};

	const { listings } = useListingsContext();
	const activeListings = listings;

	const clickHandler = (link) => {
		setActiveCategory(null);
	};

	useEffect(() => {
		if (activeListings && !activeListings.all.loading) {
			if (activeListings.all.uniqueStyles) {
				let stylesArray = Object.entries(activeListings.all.uniqueStyles)
					// 2. Filter the styles that have count > 2.
					.filter(([key, count]) => count > 2)
					.map(([key, count]) => ({
						name: key,
						id: key?.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase(),
						count: count,
					}));
				setStyles(stylesArray);
			}
			if (activeListings.all.uniqueBrands) {
				let brandsArray = activeListings.all.uniqueBrands.map((brand) => ({
					name: brand.name,
					id: brand.name?.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase(),
					count: brand.count,
				}));
				setBrands(brandsArray);
			}
			if (activeListings.all.uniqueRooms) {
				let roomsArray = Object.entries(activeListings.all.uniqueRooms)
					// 2. Filter the styles that have count > 2.
					.filter(([key, count]) => count > 2)
					.map(([key, count]) => ({
						name: key,
						id: key?.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase(),
						count: count,
					}));
				setRooms(roomsArray);
			}
		}
	}, [activeListings]);

	// Replace "&" with "%26"
	const replaceAndEncode = (text) => {
		return text;
	};

	const bestDeals = [
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/dining_room.png',
			alt: 'Dining Room, top brands, great prices',
			text: 'Seating On Sale (+20% off)',
			link: '/collections/seating?discount=20',
		},
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/accent_chair.png',
			alt: 'Accent Chairs on Sale',
			text: 'Accent Chairs on Sale (+20% off).',
			link: '/collections/seating/chairs/Accent+Chairs?discount=20',
		},
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/mcm_dresser.png',
			alt: 'Storage Furniture on Sale',
			text: 'Storage Furniture on Sale (+20% off).',
			link: '/collections/storage?discount=20',
		},
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/desks.png',
			alt: 'Desks on Sale',
			text: 'Desks on Sale (+20% off).',
			link: '/collections/tables-and-desks/desks?discount=20',
		},
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/modern_dining_tables.png',
			alt: 'Dining Tables on Sale',
			text: 'Dining Tables on Sale (+20% off).',
			link: '/collections/tables-and-desks/tables/dining-tables?discount=20',
		},
		{
			img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/ceiling_lamp.png',
			alt: 'Lighting on Sale on Sale',
			text: 'Lighting on Sale (+20% off).',
			link: '/collections/lighting?discount=20',
		},
	];

	const navClickableElements = [
		{
			id: 'On Sale',
			link: '/collections?discount=1',
			categories: newFilters.categories
				.reduce((acc, cat) => {
					if (['Storage', 'Beds', 'Tables'].includes(cat.category)) {
						let furnitureCategory = acc.find((c) => c.name === 'Furniture');

						// If it doesn't exist, create it and temporarily push it to the end
						if (!furnitureCategory) {
							furnitureCategory = {
								name: 'Furniture',
								subcategories: [],
								link: `/collections?discount=1`,
							};
							acc.push(furnitureCategory);
						}

						furnitureCategory.subcategories.push({
							name:
								cat.category === 'Storage'
									? `${cat.category} Furniture`
									: cat.category,
							link: `/collections/${cat.id}?discount=1`,
							subcategories: cat?.subCategories.map((opt) => ({
								name: opt.name,
								link: `/collections/${replaceAndEncode(
									cat.id
								)}/${replaceAndEncode(opt.id)}?discount=1`,
								subType: opt?.types?.map((type) => ({
									name: type.name,
									link: `/collections/${replaceAndEncode(
										cat.id
									)}/${replaceAndEncode(opt.id)}/${replaceAndEncode(
										type.id
									)}?discount=1`,
								})),
							})),
						});
					} else {
						acc.push({
							name: cat.category,
							subcategories: cat?.subCategories.map((opt) => ({
								name: opt.name,
								link: `/collections/${replaceAndEncode(
									cat.id
								)}/${replaceAndEncode(opt.id)}?discount=1`,
								subType: opt?.types?.map((type) => ({
									name: type.name,
									link: `/collections/${replaceAndEncode(
										cat.id
									)}/${replaceAndEncode(opt.id)}/${replaceAndEncode(
										type.id
									)}?discount=1`,
								})),
							})),
							link: `/collections/${replaceAndEncode(cat.id)}?discount=1`,
						});
					}
					return acc;
				}, [])
				.sort((a, b) =>
					a.name === 'Furniture' ? -1 : b.name === 'Furniture' ? 1 : 0
				),
			display: bestDeals,
		},
		{ id: 'New Hits', link: '/selections/top-picks' },
		{
			id: 'Furniture',
			link: '/collections',
			categories: newFilters.categories
				.filter(
					(cat) =>
						cat.category !== 'Decor' &&
						cat.category !== 'Lighting' &&
						cat.category !== 'Seating'
				)
				.map((cat) => ({
					name:
						cat.category === 'Storage'
							? `${cat.category} Furniture`
							: cat.category,
					subcategories: cat?.subCategories.map((opt) => ({
						// Just use .map, not .flatMap
						name: opt.name,
						link: `/collections/${replaceAndEncode(cat.id)}/${replaceAndEncode(
							opt.id
						)}`,
						subType: opt?.types?.map((type) => ({
							name: type.name,
							link: `/collections/${replaceAndEncode(
								cat.id
							)}/${replaceAndEncode(opt.id)}/${replaceAndEncode(type.id)}`,
						})),
					})),
					link: `/collections/${replaceAndEncode(cat.id)}`,
				})),
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/modern_dresser.png',
					alt: 'used dressers, top brands, great prices',
					text: 'Modern Dressers',
					link: '/collections/storage/dressers-chest-of-drawers?style=modern',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/mcm_credenzas.png',
					alt: 'used dressers, top brands, great prices',
					text: 'MCM Credenzas & Sideboards',
					link: '/collections/storage/sideboards-and-credenzas?style=mid_century',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/wooden_dining_tables.png',
					alt: 'Wooden Dining Tables, top brands, great prices',
					text: 'Wooden Dining Tables.',
					link: '/collections/tables-and-desks/tables/dining-tables?material=wood',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/hard_wood_desk.png',
					alt: 'Wooden Desks Tables, top brands, great prices',
					text: 'Hard Wood Desks.',
					link: '/collections/tables-and-desks/desks/desks?material=wood',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/bed_room.png',
					alt: 'Nighstands, top brands, great prices',
					text: 'Lovely Nighstands.',
					link: '/collections/storage/nightstands',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/mcm_dressers.png',
					alt: 'Dressers, top brands, great prices',
					text: 'Our Dressers.',
					link: '/collections/storage/dressers-and-chests-of-drawers',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/coffee_table.png',
					alt: 'Dressers, top brands, great prices',
					text: 'Our Coffee Tables.',
					link: '/collections/tables-and-desks/tables/coffee-tables',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/shelving.png',
					alt: 'Shelving, top brands, great prices',
					text: 'Shelving Allrounders.',
					link: '/collections/storage/bookcases-and-shelving',
				},
			],
		},
		{
			id: 'Seating',
			link: '/collections/seating',
			categories: newFilters.categories
				.filter((cat) => cat.category === 'Seating')
				.flatMap((cat) => cat?.subCategories)
				.map((opt) => ({
					name: opt.name,
					link: `/collections/seating/${replaceAndEncode(opt.id)}`,
					subcategories: opt?.types?.map((type) => ({
						name: type.name,
						link: `/collections/seating/${replaceAndEncode(
							opt.id
						)}/${replaceAndEncode(type.id)}`,
					})),
				})),
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/arm_chairs.png',
					alt: 'used chairs, top brands, great prices',
					text: 'Our Lovely MCM Armchairs.',
					link: '/collections/seating/armchairs/arm-chairs?style=mid_century',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/living_room.png',
					alt: 'used sofas, top brands, great prices',
					text: 'Our Sofa Selections.',
					link: '/collections/seating/sofas/sofas',
				},

				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/chair_set.png',
					alt: 'used chair sets that are unique, top brands, great prices',
					text: 'Chair Sets to Love.',
					link: '/collections/seating/chairs/dining-chairs?keyword=Set',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/hermann_miller_seating.png',
					alt: 'Hermann Miller Office Chairs sets that are unique, top brands, great prices',
					text: 'Hermann Miller Office Chairs.',
					link: '/collections/seating/chairs?brand=Herman+Miller',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/bench.png',
					alt: 'Benches that are unique, top brands, great prices',
					text: 'Benches.',
					link: '/collections/seating/benches',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/sectional.png',
					alt: 'Sectionals that are unique, top brands, great prices',
					text: 'Our Sectionals.',
					link: '/collections/seating/sofas/sectionals',
				},

				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/accent_chair_2.png',
					alt: 'Accent Chairs, top brands, great prices',
					text: 'Accent Chairs.',
					link: '/collections/seating/chairs/accent-chairs',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/ottomans.png',
					alt: 'Ottomans, top brands, great prices',
					text: 'Comfy Ottomans.',
					link: '/collections/seating/ottomans-and-footstools',
				},
			],
		},
		{
			id: 'Home Decor',
			link: '/collections/decor',
			categories: newFilters.categories
				.filter((cat) => cat.category === 'Decor')
				.flatMap((cat) => cat?.subCategories)
				.map((opt) => ({
					name: opt.name,
					link: `/collections/decor/${replaceAndEncode(opt.id)}`,
					subcategories: opt?.types?.map((type) => ({
						name: type.name,
						link: `/collections/decor/${replaceAndEncode(
							opt.id
						)}/${replaceAndEncode(type.id)}`,
					})),
				})),
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/rug.png',
					alt: 'used Rugs, top brands, great prices',
					text: 'Our Rug Selections.',
					link: '/collections/decor/rugs-and-carpets',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/Mirror.png',
					alt: 'used Mirrors, top brands, great prices',
					text: 'Our Wall Mirrors.',
					link: '/collections/decor/mirrors/wall-mirrors',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/vase.png',
					alt: 'unique vases, top brands, great prices',
					text: 'Our Vases.',
					link: '/collections/decor/decorative-accessories/vases',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/painting.png',
					alt: 'unique paintings, top brands, great prices',
					text: 'Paintings & Wall Art.',
					link: '/collections/decor/wall-art',
				},
			],
		},
		{
			id: 'Lighting',
			link: '/collections/lighting',
			categories: newFilters.categories
				.filter((cat) => cat.category === 'Lighting')
				.flatMap((cat) => cat?.subCategories)
				.map((opt) => ({
					name: opt.name,
					link: `/collections/lighting/${replaceAndEncode(opt.id)}`,
					subcategories: opt?.types?.map((type) => ({
						name: type.name,
						link: `/collections/lighting/${replaceAndEncode(
							opt.id
						)}/${replaceAndEncode(type.id)}`,
					})),
				})),
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/floor_lamp.png',
					alt: 'used Floor Lamps, top brands, great prices',
					text: 'Our Floor Lamps.',
					link: '/collections/lighting/floor-lamps',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/desk_lamp.png',
					alt: 'used Table Lamps, top brands, great prices',
					text: 'Discover Table Lamps.',
					link: '/collections/lighting/table-lamps',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/ceiling_lamp.png',
					alt: 'used Ceiling Lamps, top brands, great prices',
					text: 'Ceiling & Wall Lamps.',
					link: '/collections/lighting/ceiling-and-wall-lamps',
				},
			],
		},
		{
			id: 'Room',
			link: '/room',
			categories: rooms
				? rooms.map((cat) => ({
						...cat,
						link: `/room/${cat.id}`,
				  }))
				: [],
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/rooms/living_room.jpg',
					alt: 'Living Room Furniture, top brands, great prices',
					text: 'Living Room Furniture.',
					link: '/room/living_room',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/rooms/dining_room.jpg',
					alt: 'Dining Room Furniture, top brands, great prices',
					text: 'Dining Room Furniture.',
					link: '/room/dining_room',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/rooms/bedroom.jpg',
					alt: 'Bedroom Furniture, top brands, great prices',
					text: 'Bedroom Furniture.',
					link: '/room/bedroom',
				},
			],
		},
		{
			id: 'Style',
			link: '/style',
			categories: styles
				? styles.map((cat) => ({
						...cat,
						link: `/style/${cat.name}`,
				  }))
				: [],
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/style/vintage.jpg',
					alt: 'vintage Furniture, top brands, great prices',
					text: 'How About Some Vintage.',
					link: '/style/vintage',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/style/mid_century_modern.jpg',
					alt: 'Mid Century Modern Furniture, top brands, great prices',
					text: 'Mid Century Modern.',
					link: '/style/mid_century',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/style/mission.jpg',
					alt: 'Mission Style Furniture, top brands, great prices',
					text: 'Mission Style.',
					link: '/style/mission',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/style/undustrial.jpg',
					alt: 'Industrial Style Furniture, top brands, great prices',
					text: 'Industrial Style.',
					link: '/style/industrial',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/style/victorian.jpg',
					alt: 'Victorian Style Furniture, top brands, great prices',
					text: 'Victorian Style.',
					link: '/style/victorian',
				},
			],
		},
		{
			id: 'Brand',
			link: '/brand?op=branded',
			categories: brands
				? brands.map((cat) => ({
						...cat,
						link: `/brand/${replaceAndEncode(cat.id)}`,
				  }))
				: [],
			display: [
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/we.png',
					alt: 'Used West Elm Furniture, top brands, great prices',
					text: 'West Elm Furniture.',
					link: '/brand/west-elm',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/pb.png',
					alt: 'Pottery Barn Furniture',
					text: 'Pottery Barn Furniture.',
					link: '/brand/pottery-barn',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/rh.png',
					alt: 'Pottery Barn Furniture',
					text: 'Restoration Hardware.',
					link: '/brand/restoration-hardware',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/c&b.png',
					alt: 'Crate & Barrel Furniture',
					text: 'Crate & Barrel Furniture.',
					link: '/brand/crate-and-barrel',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/article.png',
					alt: 'Article Furniture',
					text: 'Article.',
					link: '/brand/article',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/classic_designs.png',
					alt: 'Classic Designs & Brands',
					text: 'Classic Designs & Designers',
					link: '/collections?op=designer',
				},
				{
					img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategoriesRound/hermann_miller_seating.png',
					alt: 'Herman Miller Chairs',
					text: 'Herman Miller Chairs.',
					link: '/brand/herman-miller?category=seating',
				},
			],
		},
	];

	const capitalizeEachWord = (text) => {
		const specialCases = {
			'1980_s': '1980s',
			'1990_s': '1990s',
			'20_th_century': '20th Century',
		};

		if (specialCases[text]) {
			return specialCases[text];
		}

		// For words that aren't special cases
		return text
			?.replace(/_/g, ' ')
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const [isScrollingUp, setIsScrollingUp] = useState(true);
	const [prevScrollPos, setPrevScrollPos] = useState(
		global?.window && window.scrollY
	);

	// Inside your component
	const handleScroll = useCallback(() => {
		const currentScrollPos = global?.window && window.scrollY;
		setIsScrollingUp(
			global?.window && window.scrollY <= 0
				? true
				: prevScrollPos > currentScrollPos
		);
		setPrevScrollPos(currentScrollPos);
	}, [prevScrollPos]); // Include all dependencies here

	const [windowInnerWidth, setWindowInnerWidth] = useState();
	useEffect(() => {
		setWindowInnerWidth(window.innerWidth);
		global?.window && window.addEventListener('scroll', handleScroll);
		return () => {
			global?.window && window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return (
		<>
			{windowInnerWidth > 900 &&
			(pathname.includes('collections') ||
				pathname.includes('brand') ||
				pathname.includes('location') ||
				pathname.includes('style') ||
				pathname.includes('room') ||
				pathname.includes('show') ||
				pathname.includes('cart') ||
				pathname.includes('impact') ||
				pathname.includes('product') ||
				pathname.includes('blog') ||
				pathname.includes('how-it-works') ||
				pathname.includes('about') ||
				pathname.includes('selections') ||
				pathname.includes('pages') ||
				pathname === '/') ? (
				<>
					<motion.div
						className={`${classes.container}`}
						variants={{
							open: { opacity: 1, top: '73px' },
							closed: { opacity: 0, top: '-75px' },
						}}
						transition={{ ease: 'easeOut', duration: 0.4 }}
						initial={isScrollingUp ? 'open' : 'closed'}
						animate={isScrollingUp ? 'open' : 'closed'}
						onMouseLeave={handleMainMouseLeave}
					>
						<div className={classes.mainNavContainter}>
							<ul className={classes.main}>
								{navClickableElements.map((element, idx) => (
									<li key={idx} className={classes.mainItem}>
										<Link
											href={element.link}
											className={classes.linkItem}
											onClick={() => clickHandler()}
											onMouseEnter={() => handleMainItemMouseEnter(element)}
										>
											<span
												aria-hidden="true"
												className={
													element.id === 'On Sale'
														? classes.redText
														: classes.regularText
												}
											>
												{element.id} {element.id === 'On Sale' && ' 🌶'}{' '}
												{element.id === 'New Hits' && ' 🌸'}
											</span>
											<span className={classes.borderBottom} />
										</Link>
									</li>
								))}
							</ul>
						</div>
						<AnimatePresence mode="wait">
							{activeCategory && activeCategory?.categories?.length > 0 && (
								<motion.section
									className={classes.subNavContainer}
									initial={{ opacity: 0, y: -20 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: 10 }}
									transition={{ duration: 0.2 }}
								>
									{' '}
									<section className={classes.flexRow}>
										<div className={classes.leftSection}>
											<Link
												href={activeCategory.link}
												onClick={() => clickHandler()}
												className={classes.subHeader}
											>
												{activeCategory.id === 'Room' ||
												activeCategory.id === 'Brand' ||
												activeCategory.id === 'Style'
													? 'Shop By '
													: 'See All '}{' '}
												{activeCategory.id}
											</Link>
											<div className={classes.flexRow}>
												<ul className={classes.categoriesList}>
													{activeCategory.categories
														.sort((a, b) => b.count - a.count)
														.filter(
															(category) =>
																category.name !== '' &&
																category.name !== 'undefined' &&
																category.name !== 'null' &&
																category.name !== null &&
																category.name !== 'NaN' &&
																category.name !== 'Undefined'
														)
														.slice(0, 10)
														.map((category, idx) => (
															<li key={idx}>
																<Link
																	href={category.link}
																	onClick={() => clickHandler()}
																	className={classes.listItem}
																	onMouseEnter={() =>
																		handleCategoryMouseEnter(category)
																	}
																>
																	{capitalizeEachWord(category.name)}
																	{category.count
																		? ` (${category.count} items)`
																		: ''}
																</Link>
															</li>
														))}
												</ul>
												{hoveredCategory && hoveredCategory?.length > 0 && (
													<motion.ul
														className={classes.subcategories}
														key={hoveredCategory}
														initial={{ opacity: 0, y: -20 }}
														animate={{ opacity: 1, y: 0 }}
														exit={{ opacity: 0, y: 10 }}
														transition={{ duration: 0.2 }}
													>
														{hoveredCategory?.map((sub, subIdx) => (
															<li key={subIdx}>
																<Link
																	href={sub.link}
																	className={classes.listItem}
																	onClick={() => clickHandler()}
																	onMouseEnter={() =>
																		handleSubCategoryMouseEnter(sub)
																	}
																>
																	{sub.name}
																</Link>
															</li>
														))}
													</motion.ul>
												)}

												{hoveredSubCategory &&
													hoveredSubCategory?.subType?.length > 0 && (
														<motion.ul
															className={classes.subcategories}
															key={hoveredSubCategory}
															initial={{ opacity: 0, y: -20 }}
															animate={{ opacity: 1, y: 0 }}
															exit={{ opacity: 0, y: 10 }}
															transition={{ duration: 0.2 }}
														>
															{hoveredSubCategory.subType.map((sub, subIdx) => (
																<li key={subIdx}>
																	<Link
																		href={sub.link}
																		className={classes.listItem}
																		onClick={() => clickHandler()}
																	>
																		{sub.name}
																	</Link>
																</li>
															))}
														</motion.ul>
													)}
											</div>
										</div>
									</section>
									<section className={classes.flexCol}>
										<h4 className={classes.subHeader}>Curated Collections</h4>

										<motion.ul
											className={`${classes.rightSection} ${classes.fadeIn}`}
										>
											{activeCategory &&
												activeCategory.display &&
												activeCategory.display.map((item, idx) => (
													<li key={idx}>
														<Link
															href={item.link}
															className={classes.imgContainer}
															onClick={() => clickHandler()}
														>
															<img
																src={`${item.img}?tr=w-600`}
																alt={item.alt}
																className={classes.img}
															/>
															<div className={classes.overlay}>
																<h6 className={classes.text}>{item.text}</h6>
															</div>
														</Link>
													</li>
												))}
										</motion.ul>
									</section>
								</motion.section>
							)}
						</AnimatePresence>
					</motion.div>
				</>
			) : (
				''
			)}
		</>
	);
};
export default NavigationBarClickable;
