'use client';
import Link from 'next/link';
import classes from './Navbar.module.css';
import { usePathname } from 'next/navigation';

const CheckoutNavBar = () => {
	const pathname = usePathname();

	return (
		<>
			{pathname?.includes('checkout') && (
				<div className={classes.nav}>
					<section className={classes.main}>
						<section className={classes.colFull}>
							<section className={classes.Navbar}>
								<Link href={'/'}>
									<img
										className={classes.logo}
										src={'https://ik.imagekit.io/kuj9zqlrx0or/logo-black.avif'}
										alt="KASHEW Second Hand Furniture San Francisco"
									/>
								</Link>
							</section>
						</section>
					</section>
				</div>
			)}
		</>
	);
};

export default CheckoutNavBar;
