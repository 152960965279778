import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import classes from '../Navbar.module.css';
import { useCartContext } from '@/context/cart';
import { XCircle } from 'react-feather';

const CartModal = (props) => {
	const { refCart, setShowCartMenu } = props;

	const { REMOVE_FROM_CART, cart } = useCartContext();

	const router = useRouter();

	// const findDisplayTitle = (listing) => {
	// 	if (listing.velouAttributes?.generatedContent) {
	// 		const title = listing.velouAttributes.generatedContent.find
	// 			? listing.velouAttributes.generatedContent.find(
	// 					({ fieldName }) => fieldName === 'productTitle'
	// 			  )
	// 			: null;
	// 		if (title?.values) {
	// 			return title.values['en-us'];
	// 		}
	// 	}
	// 	return listing.title;
	// };

	const removeFromCartHandler = (e, listing) => {
		e.preventDefault();
		REMOVE_FROM_CART(listing._id);
		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			process.env.OG_DB === 'true' &&
			process.env.OG_DB === 'true' &&
			global?.window &&
			window.analytics.track('remove_from_cart', {
				currency: 'USD',
				value: listing.price * 0.2,
				items: [
					{
						item_id: listing._id,
						item_name: listing.title,
						currency: 'USD',
						item_brand: listing.brand
							? listing.brand
							: listing.velouAttributes?.manufacturer?.length > 0
							? listing.velouAttributes?.manufacturer[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: listing.velouAttributes?.brands?.length > 0
							? listing.velouAttributes?.brands[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						price: listing.price,
					},
				],
			});
	};

	const goToCheckoutHandler = () => {
		let items = [];
		for (let listing of cart.listings) {
			items.push({ id: listing._id, listingPrice: listing.price });
		}

		global?.window && localStorage.setItem('checkout', JSON.stringify(items));
		global?.window && localStorage.removeItem('offerInfo');
		router.push('/checkout');
	};

	return (
		<>
			<motion.div
				initial={{ opacity: 0, x: '100px' }}
				animate={{ opacity: 1, x: 0 }}
				transition={{
					duration: 0.4,
					type: 'spring',
					stiffness: 400,
					damping: 40,
				}}
				className={classes.favoriteNavMenu}
				ref={refCart}
				onClick={() => setShowCartMenu(false)}
			>
				<div className={classes.notificationHeader}>
					<p className={classes.cartHeader}>
						Your Cart{' '}
						{cart.listings.length > 0
							? ` - (${cart.listings.length} items)`
							: ''}
					</p>
				</div>

				{cart.loading && (
					<>
						<div className={classes.notify}>
							<section className={classes.profileNavMenuItem1}>
								<div className={classes.placeholderBox} />
								<div className={classes.profileInfo}>
									<div className={classes.textPlaceholderLong} />
									<div className={classes.textPlaceholderShort} />
								</div>
							</section>
						</div>
						<div className={classes.notify}>
							<section className={classes.profileNavMenuItem1}>
								<div className={classes.placeholderBox} />
								<div className={classes.profileInfo}>
									<div className={classes.textPlaceholderLong} />
									<div className={classes.textPlaceholderShort} />
								</div>
							</section>
						</div>
					</>
				)}
				<div className={classes.overflowCart}>
					{cart.listings.length > 0 && !cart.loading ? (
						<>
							{cart.listings.map(
								(listing, idx) =>
									listing && (
										<div className={classes.notify} key={listing._id}>
											<Link
												className={classes.profileNavMenuItem1}
												key={idx}
												href={`/product/${listing.kashewId}/${listing?.title
													?.toLowerCase()
													.replace(/[_\W]+/g, '-')
													.replace(/^-+/, '')
													.replace(/-+$/, '')}`}
											>
												<Image
													width={50}
													height={50}
													src={listing.images[0]}
													className={classes.listingImage}
													alt={listing.title}
												/>
												<div className={classes.profileInfo}>
													<h4 className={classes.title}>{listing.title}</h4>
													<p className={`${classes.profileText} p-text`}>
														${listing.price}
													</p>
												</div>
												<XCircle
													size={8}
													className={classes.delete}
													onClick={(e) => removeFromCartHandler(e, listing)}
												/>
											</Link>
										</div>
									)
							)}
						</>
					) : !cart.loading ? (
						<div className={classes.buySomeShit}>
							<Image
								width={70}
								height={70}
								src={'https://ik.imagekit.io/kuj9zqlrx0or/Static/emptyBox.png'}
								className={classes.emptyBox}
								alt="No consignment offers used furnitue Kashew"
							/>
							<div className={classes.col}>
								<h3 className={classes.buyText}>
									Here I am, an empty basket, patiently waiting for an item to
									call my own.
								</h3>
								<div
									className={classes.buyLink}
									onClick={() => router.push('/collections')}
								>
									Explore Kashew
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				{cart.listings.length > 0 && (
					<div className={classes.viewCart}>
						<p onClick={goToCheckoutHandler} className={classes.viewCartButton}>
							Go to Checkout{' '}
						</p>
					</div>
				)}
			</motion.div>
		</>
	);
};
export default CartModal;
