'use client';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import BuyNav from './BuyNav';
import SearchBar from './NavbarComponents/SearchBar';

import AuthUser from '@/utils/AuthUser';
import { useDisableBodyScroll } from '@/utils/DisableScroll';

import classes from './Navbar.module.css';
import NotificationsModal from './NavbarComponents/NotificationsModal';

// Icons
import { useAuthContext } from '@/context';
import { useCartContext } from '@/context/cart';
import { useChatsContext } from '@/context/chats';
import { useFavoritesContext } from '@/context/favorites';
import { useNotificationsContext } from '@/context/notifications';
import AllChatsOverlay from '../Chats/AllChatsOverlay';
import CartModal from './NavbarComponents/CartModal';
import ProfileModal from './NavbarComponents/ProfileModal';
import { Bell, Heart, MessageCircle, User } from 'react-feather';

const Navbar = () => {
	const refFavorite = useRef();
	const refCart = useRef();
	const refChat = useRef();
	const refNavMenu = useRef();
	const refNotifications = useRef();

	const router = useRouter();
	const searchParams = useSearchParams(); // Use this to access query parameters
	const pathname = usePathname();

	const [onScroll, setOnScroll] = useState(false);
	const [showChat, setShowChat] = useState(false);
	const [chatSelection, setChatSelection] = useState();

	const [requestAuth, setRequestAuth] = useState();

	const { auth, setAuth } = useAuthContext();
	const { chats, REMOVE_CHAT_NOT } = useChatsContext();
	const {
		notifications,
		LOAD_NOTIFICATIONS,
		PREVIEW_NOTIFICATIONS,
		REMOVE_NOTIFICATION,
	} = useNotificationsContext();

	useEffect(() => {
		const chat = searchParams.get('chat');
		const listing = searchParams.get('listing');
		if (chat || listing) {
			setShowChat(true);
		}
	}, [searchParams]);

	const [showNotifications, setShowNotifications] = useState(false);

	const loadMoreHandler = () => {
		LOAD_NOTIFICATIONS(true);
		setShowNotifications(true);
	};

	const [showFavoritesMenu, setShowFavoritesMenu] = useState(false);

	// Favorite List logic
	const [showCartMenu, setShowCartMenu] = useState(false);

	//Profile icon nav menu logic
	const [showNavMenu, setShowNavMenu] = useState(false);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			const dropdowns = [
				{
					ref: refNavMenu,
					state: showNavMenu,
					setState: setShowNavMenu,
				},
				{
					ref: refCart,
					state: showCartMenu,
					setState: setShowCartMenu,
				},
				{
					ref: refChat,
					state: showChat,
					setState: setShowChat,
				},
				{
					ref: refNotifications,
					state: showNotifications,
					setState: setShowNotifications,
				},
				{
					ref: refFavorite,
					state: showFavoritesMenu,
					setState: setShowFavoritesMenu,
				},
			];

			for (const { ref, state, setState } of dropdowns) {
				if (state && ref.current && !ref.current.contains(e.target)) {
					setState(false);
				}
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [
		showNavMenu,
		showCartMenu,
		showNotifications,
		showFavoritesMenu,
		showChat,
		pathname,
	]);

	useEffect(() => {
		if (showChat && !auth?.isAuthenticated) {
			setRequestAuth(true);
		}
	}, [showChat]);

	useDisableBodyScroll(requestAuth);

	//Bell notifications

	const [displayNotsNumber, setDisplayNotsNumber] = useState(10);
	const [maxedOut, setMaxedOut] = useState(false);

	const observer = useRef();
	const lastNot = useCallback(
		(node) => {
			if (notifications.notifications.length === 0 || maxedOut) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setDisplayNotsNumber(displayNotsNumber + 10);
				}
			});
			if (node) observer.current.observe(node);
		},
		[notifications, displayNotsNumber]
	);

	useEffect(() => {
		if (
			notifications.notifications.length > 0 &&
			notifications.notifications.length < displayNotsNumber
		) {
			setMaxedOut(true);
		}
	}, [notifications, displayNotsNumber]);

	const notificationsClickHandler = () => {
		setMaxedOut(false);
		setDisplayNotsNumber(10);
		// PREVIEW_NOTIFICATIONS();
		setShowNotifications(true);
	};

	//   Cart

	const { cart } = useCartContext();

	//   Cart End
	const counterOffer = (notification) => {
		REMOVE_CHAT_NOT(notification.notification._id);
		global?.window && localStorage.setItem('showOffers', true);
		router.push(
			`/product/${notification.listing.kashewId}/${notification.listing?.title
				?.toLowerCase()
				.replace(/[_\W]+/g, '-')
				.replace(/^-+/, '')
				.replace(/-+$/, '')}`
		);
	};

	const controlNavbar = () => {
		if (global?.window && window.scrollY > 150) {
			setOnScroll(true);
		} else {
			setOnScroll(false);
		}
	};

	useEffect(() => {
		global?.window && window.addEventListener('scroll', controlNavbar);
		return () => {
			global?.window && window.removeEventListener('scroll', controlNavbar);
		};
	}, [onScroll]);

	const { favorites } = useFavoritesContext();

	return (
		<>
			{pathname !== '/login' &&
			pathname !== '/register' &&
			pathname !== '/checkout' &&
			pathname !== '/consign-form' &&
			!pathname.includes('order-summary') &&
			!pathname.includes('info') &&
			!pathname.includes('help') ? (
				<div className={classes.nav}>
					<section className={classes.main}>
						{requestAuth && (
							<AuthUser
								open={!auth?.isAuthenticated}
								setRequestAuth={setRequestAuth}
							/>
						)}

						<section className={classes.colFull}>
							<div className={classes.Navbar}>
								<Link href="/">
									<Image
										height={25}
										width={150}
										className={classes.logo}
										src={'https://ik.imagekit.io/kuj9zqlrx0or/logo-black.avif'}
										alt="KASHEW Second Hand Furniture San Francisco"
									/>
								</Link>

								<div className={classes.navLinks}>
									<SearchBar className={classes.searchBarClass1} />

									<Link href="/collections" className={classes.navLink}>
										<h6 className={classes.text}>Shop </h6>
									</Link>

									<Link href="/how-it-works" className={classes.navLink}>
										<h6 className={classes.text}>How it Works </h6>
									</Link>

									<Link href="/about-us" className={classes.navLink}>
										<h6 className={classes.text}>About Us </h6>
									</Link>

									<Link href="/blog" className={classes.navLink}>
										<h6 className={classes.text}>Blog </h6>
									</Link>
								</div>

								<div className={classes.desktop}>
									{!auth?.loading && (
										<>
											{!auth?.isAuthenticated && (
												<span className={classes.mobileHidden}>
													<Link href="/register" className={classes.navLink}>
														<h6 className={classes.text}>Sign up </h6>
													</Link>

													<Link href="/login" className={classes.navLink}>
														<h6 className={classes.text}>Login</h6>
													</Link>

													<div className={classes.separator} />
												</span>
											)}
											<div
												className={
													showCartMenu
														? classes.iconContainerActive
														: classes.iconContainer
												}
											>
												{cart.listings.length > 0 && (
													<div className={classes.notificationNumber}>
														{cart.listings.length}
													</div>
												)}

												<Image
													width={24}
													height={24}
													src="https://ik.imagekit.io/kuj9zqlrx0or/Static/cart_icon.png"
													className={classes.icon}
													alt="Cart"
													onClick={() => setShowCartMenu(!showCartMenu)}
												/>
											</div>
											{!auth?.isAuthenticated ? (
												<div className={classes.mobile}>
													<div
														className={classes.iconContainer}
														onClick={() => router.push('/login')}
													>
														<User className={classes.icon} strokeWidth={1} />
													</div>
												</div>
											) : (
												<>
													<div
														className={classes.iconContainer}
														onClick={() => router.push('/favorites')}
													>
														{auth.isAuthenticated &&
															favorites?.listings?.length > 0 && (
																<div className={classes.notificationNumber}>
																	{favorites?.listings?.length}
																</div>
															)}

														<Heart className={classes.icon} strokeWidth={1.2} />
													</div>
													{auth?.isAuthenticated && (
														<div
															className={
																showChat || pathname.includes('/orders')
																	? classes.iconContainerActive
																	: classes.iconContainer
															}
														>
															{chats.notifications > 0 && (
																<div className={classes.notificationNumber}>
																	{chats.notifications}
																</div>
															)}
															<MessageCircle
																className={classes.icon}
																strokeWidth={1.2}
																onClick={
																	!pathname.includes('/orders')
																		? () => setShowChat(!showChat)
																		: () => setShowChat(false)
																}
															/>
														</div>
													)}
													<div
														className={
															showNotifications
																? classes.iconContainerActive
																: classes.iconContainer
														}
													>
														{notifications.notPreviewed > 0 && (
															<div className={classes.notificationNumber}>
																{notifications.notPreviewed}
															</div>
														)}

														<Bell
															strokeWidth={1.2}
															className={classes.icon}
															onClick={notificationsClickHandler}
														/>
													</div>
													<img
														className={classes.navbarImg}
														src={`${auth?.user.picture}?tr=w-50,h-50`}
														referrerPolicy="no-referrer"
														alt="User Profile"
														onClick={() => setShowNavMenu(!showNavMenu)}
													/>
												</>
											)}
											<Link
												className={classes.alignCenter}
												href="/consign-with-us"
											>
												<button className={classes.sellButton}>Sell</button>
											</Link>
										</>
									)}

									{auth?.loading && (
										<>
											<div className={classes.loadingPlaceholder} />{' '}
											<div className={classes.loadingPlaceholder} />{' '}
											<div className={classes.loadingPlaceholder} />
											<Link
												className={classes.alignCenter}
												href="/consign-with-us"
											>
												<button className={classes.sellButton}>Sell</button>
											</Link>
										</>
									)}
								</div>
								<Suspense fallback={<div className={classes.fallback}></div>}>
									{showCartMenu && (
										<CartModal
											refCart={refCart}
											setShowCartMenu={setShowCartMenu}
										/>
									)}

									{showNavMenu && (
										<ProfileModal
											auth={auth}
											setAuth={setAuth}
											refNavMenu={refNavMenu}
											setShowNavMenu={setShowNavMenu}
										/>
									)}

									{showNotifications && (
										<NotificationsModal
											refNotifications={refNotifications}
											setShowNotifications={setShowNotifications}
											displayNotsNumber={displayNotsNumber}
											counterOffer={counterOffer}
											removeNotification={REMOVE_NOTIFICATION}
											lastNot={lastNot}
											auth={auth}
											loadMoreHandler={loadMoreHandler}
										/>
									)}
								</Suspense>
							</div>
							<Suspense fallback={<div className={classes.fallback}></div>}>
								{(pathname.includes('/product') ||
									pathname.includes('/collections') ||
									pathname.includes('/brand') ||
									pathname.includes('/room') ||
									pathname.includes('/location') ||
									pathname.includes('/style') ||
									pathname.includes('/favorites') ||
									pathname.includes('/how-it-works') ||
									pathname.includes('/about') ||
									pathname.includes('/blog') ||
									pathname.includes('selections') ||
									pathname.includes('pages') ||
									pathname === '/') && <BuyNav auth={auth} />}
							</Suspense>

							<AllChatsOverlay
								open={showChat}
								refChat={refChat}
								setOpen={setShowChat}
								chatSelection={chatSelection}
								setChatSelection={setChatSelection}
							/>
						</section>
					</section>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default Navbar;
