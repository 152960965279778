import { useRouter } from 'next/navigation';
import SearchBar from './SearchBar';
import classes from '../Navbar.module.css';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { newFilters } from '@/utils/newFilters';
import { useListingsContext } from '@/context/listings';
import { ChevronDown, X } from 'react-feather';

const MainNavigationMobile = (props) => {
	const { expanded, setExpanded, refToggle, auth } = props;

	const router = useRouter();

	const [showOptions, setShowOptions] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const [displayCategories, setDisplayCategories] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [showTypes, setShowTypes] = useState(false);
	const [types, setTypes] = useState([]);
	const [styles, setStyles] = useState([]);
	const [brands, setBrands] = useState([]);
	const [rooms, setRooms] = useState([]);

	const { activeListings } = useListingsContext();

	useMemo(() => {
		if (activeListings && !activeListings.all.loading) {
			if (activeListings.all.uniqueStyles) {
				let stylesArray = Object.entries(activeListings.all.uniqueStyles)
					// 2. Filter the styles that have count > 2.
					.filter(([key, count]) => count > 2)
					.map(([key, count]) => ({
						name:
							key?.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1),
						id: key?.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase(),
						count: count,
					}));
				setStyles(stylesArray);
			}
			if (activeListings.all.uniqueBrands) {
				let brandsArray = activeListings.all.uniqueBrands
					.filter((key) => key.name !== '' && key.name !== null)
					.map((brand) => ({
						name: brand.name,
						id: brand.name
							?.replace(/ /g, '-')
							.replace(/&/g, 'and')
							.toLowerCase(),
						count: brand.count,
					}));
				setBrands(brandsArray);
			}
			if (activeListings.all.uniqueRooms) {
				let roomsArray = Object.entries(activeListings.all.uniqueRooms)
					// 2. Filter the styles that have count > 2.
					.filter(([key, count]) => count > 2)
					.map(([key, count]) => ({
						name: key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1),
						id: key?.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase(),
						count: count,
					}));
				setRooms(roomsArray);
			}
		}
	}, [activeListings]);

	useMemo(() => {
		if (displayCategories[0]?.name === 'On Sale') return;

		const onSale = {
			category: 'On Sale',
			subCategories: [
				{ name: 'Seating' },
				{ name: 'Storage' },
				{ name: 'Beds' },
				{ name: 'Tables & Desks' },
				{ name: 'Lighting' },
				{ name: 'Decor' },
			],
		};

		const NewHits = {
			category: 'New Hits',
		};

		const shopBy = {
			category: 'Shop By',
			id: 'shop-by',
			subCategories: [
				{
					name: 'Brand',
					types: brands
						.sort((a, b) => b.count - a.count)
						.slice(0, 8)
						.map((brand) => ({
							name: brand.name,
							count: brand.count,
							id: brand.id,
						})),
				},
				{
					name: 'Room',
					types: rooms
						.sort((a, b) => b.count - a.count)
						.slice(0, 5)
						.map((room) => ({
							name: room.name,
							count: room.count,
							id: room.id,
						})),
				},
				{
					name: 'Style',
					types: styles
						.sort((a, b) => b.count - a.count)
						.slice(0, 8)
						.map((style) => ({
							name: style.name,
							count: style.count,
							id: style.id,
						})),
				},
			],
		};
		const newArr = [...newFilters.categories];
		newArr.unshift(NewHits);
		newArr.unshift(onSale);
		newArr.push(shopBy);
		setDisplayCategories(newArr);
	}, [styles, brands, rooms]);

	const showOptionsHandler = (sel) => {
		const category = displayCategories.find(
			(category) => sel === category.category
		);
		setOptions(category?.subCategories || []);
		setShowOptions(true);
		setSelectedCategory(sel);
	};

	const clearHandler = () => {
		setShowOptions(false);
		setOptions([]);
		setSelectedCategory(null);
		setShowTypes(false);
		setTypes([]);
		setSelectedSubCategory(null);
	};

	const showTypesHandler = (sel, cat) => {
		if (cat !== 'On Sale') {
			const subCategory = options?.find((option) => sel === option.name);
			if (!subCategory?.types || subCategory.types.length === 0) {
				setExpanded(false);
				router.push(
					`/collections/${selectedCategory
						.replace(/ /g, '-')
						.replace(/&/g, 'and')
						.toLowerCase()}/${sel
						.replace(/ /g, '-')
						.replace(/&/g, 'and')
						.toLowerCase()}`
				);
			} else {
				if (sel === selectedSubCategory) {
					setShowTypes(false);
					setTypes([]);
					setSelectedSubCategory(null);
					return;
				} else {
					setTypes(subCategory.types);
					setShowTypes(true);
					setSelectedSubCategory(sel);
				}
			}
		} else {
			setExpanded(false);
			router.push(
				`/collections/${sel
					.replace(/ /g, '-')
					.replace(/&/g, 'and')
					.toLowerCase()}?discount=1`
			);
		}
	};

	const navigateToType = (type, typeParam) => {
		if (
			typeParam === 'Brand' ||
			typeParam === 'Room' ||
			typeParam === 'Style'
		) {
			router.push(
				`/${typeParam.toLowerCase()}/${type
					.toLowerCase()
					.replace(/ /g, '-')
					.replace(/&/g, 'and')}`
			);
		} else {
			router.push(
				`/collections/${selectedCategory
					.replace(/ /g, '-')
					.replace(/&/g, 'and')
					.toLowerCase()}/${selectedSubCategory
					.replace(/ /g, '-')
					.replace(/&/g, 'and')
					.toLowerCase()}/${type
					.replace(/ /g, '-')
					.replace(/&/g, 'and')
					.toLowerCase()}`
			);
		}
		setExpanded(false);
	};

	return (
		<Offcanvas
			show={expanded}
			onHide={() => setExpanded(false)}
			className={classes.offCanvas}
		>
			<div className={classes.mainOffDiv} ref={refToggle}>
				<X className={classes.iconClose} onClick={() => setExpanded(false)} />
				<header className={classes.headerMobile}>
					<Link href={'/'}>
						<img
							className={classes.logoMobile}
							onClick={() => setExpanded(false)}
							src={
								'https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/logo/svg/logo-black.svg'
							}
							alt="KASHEW Second Hand Furniture San Francisco"
						/>
					</Link>
					<SearchBar
						expanded={expanded}
						setExpanded={setExpanded}
						className={classes.searchBar2}
					/>
				</header>

				<section className={classes.bodyOffcanvas}>
					{displayCategories?.map((category, idx) => {
						const isActive = selectedCategory === category.category;

						return (
							<section className={classes.buttonSection} key={idx}>
								<button
									key={category.category}
									onClick={
										category.category !== 'New Hits'
											? isActive
												? clearHandler
												: () => showOptionsHandler(category.category)
											: () => {
													setExpanded(false);
													router.push(`/selections/top-picks`);
											  }
									}
									className={classes.navLinkOffcanvas}
								>
									<p
										className={
											category.category === 'On Sale'
												? classes.textOnSale
												: classes.textCanvas
										}
									>
										{' '}
										{category.category === 'Storage'
											? 'Storage Furniture'
											: category.category === 'Tables & Desks'
											? 'Tables, Desks & Coffee Tables'
											: category.category === 'Decor'
											? 'Home Decor'
											: category.category}{' '}
										{category.category === 'On Sale' && ' 🌶'}{' '}
										{category.category === 'New Hits' && ' 🌟'}
									</p>
									{category.category !== 'New Hits' && (
										<ChevronDown
											size={18}
											className={isActive ? classes.flip : classes.iconDrop}
										/>
									)}
								</button>
								{isActive && showOptions && options && (
									<section className={classes.colGap1}>
										{category.category !== 'Shop By' && (
											<Link
												className={classes.seeAll}
												href={
													category.category === 'On Sale'
														? `/collections?discount=1`
														: `/collections/${category.category
																.replace(/ /g, '-')
																.replace(/&/g, 'and')
																.toLowerCase()}`
												}
												onClick={() => setExpanded(false)}
											>
												See all {category.category}{' '}
											</Link>
										)}
										{options?.map((option, idx) => {
											return (
												<div
													key={idx}
													className={
														idx + 1 === options?.length
															? classes.buttonSectionLast
															: classes.buttonSection
													}
												>
													<button
														onClick={() =>
															showTypesHandler(option.name, category.category)
														}
														className={
															idx + 1 === options?.length
																? classes.navLinkOffCanvasSubLast
																: classes.navLinkOffcanvasSub
														}
													>
														<p className={classes.textCanvasSub}>
															{' '}
															{option.name === 'Storage'
																? 'Storage Furniture'
																: option.name === 'Tables & Desks'
																? 'Tables, Desks & Coffee Tables'
																: option.name === 'Decor'
																? 'Home Decor'
																: option.name}{' '}
															{category.name === 'On Sale' && '(+20% off)'}
														</p>
														{option.types && option.types.length > 0 && (
															<ChevronDown
																size={18}
																className={
																	selectedSubCategory === option.name
																		? classes.flip
																		: classes.iconDrop
																}
															/>
														)}
													</button>
													{showTypes && selectedSubCategory === option.name && (
														<section className={classes.colGap}>
															{category.category !== 'Shop By' && (
																<Link
																	className={classes.seeAll}
																	href={
																		category.category === 'On Sale'
																			? `/collections/${option.name
																					.replace(/ /g, '-')
																					.replace(/&/g, 'and')
																					.toLowerCase()}?discount=1`
																			: `/collections/${category.category
																					.replace(/ /g, '-')
																					.replace(/&/g, 'and')
																					.toLowerCase()}/${option.name
																					.replace(/ /g, '-')
																					.replace(/&/g, 'and')
																					.toLowerCase()}`
																	}
																	onClick={() => setExpanded(false)}
																>
																	See all {option.name}{' '}
																</Link>
															)}
															<section className={classes.box}>
																{types.map((type, idx) => (
																	<button
																		className={classes.navLinkOffcanvasSub}
																		key={idx}
																		onClick={() =>
																			navigateToType(type.name, option.name)
																		}
																	>
																		<p className={classes.textCanvasSub}>
																			{type.name.replace(/_/g, ' ')}
																		</p>
																	</button>
																))}
															</section>
														</section>
													)}
												</div>
											);
										})}
									</section>
								)}
							</section>
						);
					})}
				</section>

				<section className={classes.subLinks}>
					<Link
						onClick={() => setExpanded(false)}
						href={'/consign-with-us'}
						className={classes.sellButtonOffcanvas}
					>
						Sell Furniture
					</Link>

					{!auth.isAuthenticated && (
						<>
							<Link
								onClick={() => setExpanded(false)}
								href={'/login'}
								className={classes.navLinkOffcanvas1}
							>
								<p className={classes.textCanvas1}> Login </p>
							</Link>
						</>
					)}

					<Link
						onClick={() => setExpanded(false)}
						href={'/how-it-works'}
						className={classes.navLinkOffcanvas1}
					>
						<p className={classes.textCanvas1}> How it Works </p>
					</Link>

					<Link
						onClick={() => setExpanded(false)}
						href={'/about-us'}
						className={classes.navLinkOffcanvas1}
					>
						<p className={classes.textCanvas1}> About Us </p>
					</Link>

					<Link
						onClick={() => setExpanded(false)}
						href={'/blog'}
						className={classes.navLinkOffcanvas1}
					>
						<p className={classes.textCanvas1}> Blog </p>
					</Link>
				</section>
			</div>
		</Offcanvas>
	);
};

export default MainNavigationMobile;
