'use client';
import axios from 'axios';
import { createContext, useState, useContext, useEffect } from 'react';
import { useAuthContext } from '.';
import Cookies from 'js-cookie';

// Create the cart context with default values
const DiscountsContext = createContext({
	loading: false,
	discounts: [],
	LOAD_DISCOUNTS: () => {},
});

// Create a provider component for the cart context
export const DiscountsProvider = ({ children }) => {
	const [discounts, setDiscounts] = useState({
		loading: false,
		discounts: [],
	});

	const { auth } = useAuthContext();

	const LOAD_DISCOUNTS = async () => {
		setDiscounts((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.get(`${process.env.NEXT_PUBLIC_SERVER_API}/api/payments/discounts`, {
				headers: {
					'x-auth-token': Cookies.get('token'),
				},
			})
			.then((res) => {
				setDiscounts((prevState) => ({
					discounts: res.data,
					loading: false,
				}));
			})
			.catch((error) => {
				setDiscounts((prevState) => ({
					...prevState,
					loading: false,
				}));
			});
	};

	useEffect(() => {
		if (!auth.loading && auth.isAuthenticated) {
			LOAD_DISCOUNTS();
		} else if (!auth.isAuthenticated) {
			setDiscounts({
				loading: false,
				discounts: [],
			});
		}
	}, [auth]);

	return (
		<DiscountsContext.Provider
			value={{
				discounts,
				LOAD_DISCOUNTS,
			}}
		>
			{children}
		</DiscountsContext.Provider>
	);
};

export const useDiscountsContext = () => {
	return useContext(DiscountsContext);
};
